



























































import { Component, Vue } from 'vue-property-decorator';
import DeleteButton from '@/components/DeleteButton.vue';
import { EventBus } from '@/main';
import { updateObjectList } from '@/utils';
import {CreateOrganizationDTO, OutputOrganizationDTO} from "@/api";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Loading,
    DeleteButton,
  }
})
export default class OrganisationsList extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];
  public organizations: OutputOrganizationDTO[]|null = null

  public createOrganisationName: string = '';

  public async created() {
    EventBus.$on('organisation', (data) => {
      this.organizations = updateObjectList(this.organizations, data);
    });
  }

  public async mounted() {
    await this.updateOrganisations();
  }

  public async updateOrganisations() {
    this.organizations = await this.$store.dispatch('organizations/getAll');
  }

  public async createOrganization() {
    if (await this.$validator.validateAll()) {
      const dto: CreateOrganizationDTO = {
        name: this.createOrganisationName,
      };

      await this.$store.dispatch('organizations/create', { data: dto });

      // Soft reload the page to show the new organisation
      await this.softReload();
    }
  }

  public async softReload() {
    this.resetCreateForm();
    this.organizations = null;
    await this.updateOrganisations();
  }

  public resetCreateForm() {
    this.createOrganisationName = '';
  }

  public async deleteOrganization(id) {
    await this.$store.dispatch('organizations/remove', { organizationId: id });
  }

  get loading() {
    return this.organizations === null;
  }
}
